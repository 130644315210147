import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useRef } from 'react';
import { arrayOf, number, shape, string } from 'prop-types';
import { whereAmI } from '@magalu/mixer-utils';
import { Container } from './Skeleton.styles';
var SVG_XMLS = 'http://www.w3.org/2000/svg';
var Skeleton = function Skeleton(_ref) {
  var config = _ref.config,
    containerHeight = _ref.height,
    containerWidth = _ref.width;
  var skeletonRef = useRef(null);
  var color = (config == null ? void 0 : config.color) || '#D3DADD';
  React.useEffect(function () {
    if (whereAmI.onClient && skeletonRef.current) {
      var svg = document.createElementNS(SVG_XMLS, 'svg');
      svg.setAttribute('xmlns', SVG_XMLS);
      svg.setAttribute('width', '100%');
      svg.setAttribute('viewBox', "0 0 100% 100%");
      config.body.forEach(function (row) {
        var _row = _slicedToArray(row, 1),
          _row$ = _row[0],
          height = _row$.height,
          _row$$radius = _row$.radius,
          radius = _row$$radius === void 0 ? 0 : _row$$radius,
          width = _row$.width,
          x = _row$.x,
          y = _row$.y;
        var skeletonColor = color.replace('#', '%23');
        var rect = document.createElementNS(SVG_XMLS, 'rect');
        rect.setAttribute('width', String(width));
        rect.setAttribute('height', String(height));
        rect.setAttribute('x', String(x));
        rect.setAttribute('y', String(y));
        rect.setAttribute('rx', String(radius));
        rect.setAttribute('fill', skeletonColor);
        svg.appendChild(rect);
      });
      var svgHtml = "data:image/svg+xml,".concat(svg.outerHTML.toString());
      skeletonRef.current.setAttribute('style', "\n          background-image: url('".concat(svgHtml, "');\n        "));
    }
  }, []);
  return React.createElement(Container, {
    "data-testid": "skeleton",
    height: containerHeight,
    width: containerWidth,
    ref: skeletonRef
  });
};
Skeleton.defaultProps = {
  config: {
    body: []
  },
  height: '0px',
  width: '0px'
};
process.env.NODE_ENV !== "production" ? Skeleton.propTypes = {
  config: shape({
    body: arrayOf(arrayOf(shape({
      height: number,
      radius: number,
      x: number,
      y: number
    }))),
    color: string
  }),
  height: string,
  width: string
} : void 0;
export default Skeleton;